<script lang="ts">
export default defineComponent({
    name: 'LandingLink',
    props: {
        blok: {
            type: Object as PropType<LandingLinkBlokInterface>,
            required: true,
        },
    },
});
</script>

<template>
    <div v-editable="blok">
        <link-with-arrow
            v-if="blok.text && blok.url"
            :centered-on-mobile="false"
            :link="blok.url">
            {{ blok.text }}
        </link-with-arrow>
    </div>
</template>
